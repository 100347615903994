module.exports = {

	'title_welcome': 'ようこそ, ',
	'title_second_welcome': '様',
	'information': 'アヤナでのご体験が特別なものとなるよう特典をご用意しております。 会員の方は、無料宿泊、アップグレード、その他会員特典、ウェルカムアメニティをご利用いただけます。',
	'member_name_label': 'メンバー名',
	'membership_no': 'メンバーシップナンバー.',
	'label_membership': 'メンバーシップ ',
	'membership_gold_label': 'Gold',
	'membership_silver_label': 'Silver',
	'membership_platinum_label': 'Platinum',
	'point_label': 'あなたのポイント',
	'menu_tab_profile': 'プロフィールページ',
	'menu_tab_my_redeem': 'ポイントの交換',
	'menu_tab_password_change': 'パスワードを変更する',
	'menu_tab_missing_point': '不足ポイント',
	'menu_tab_reward_history': 'リワード履歴',
	'menu_tab_future_booking': '先々のご予約',
	'menu_tab_feedback': 'フィードバック',
	'menu_tab_my_voucher': 'バウチャー',
	'menu_tab_transaction_history': 'ポイント履歴',
	'menu_tab_my_reservation': '予約詳細',
	'label_your_point': 'お客様のポイント',
	'label_expired_on': '有効期限',
	'label_required_nex_point': 'まで %pts% ポイント'
}