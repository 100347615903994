module.exports = {

	'label_edit_profile': '情報を更新する。',
	'label_id_card': 'IDカード',
	'label_no_password': 'パスポート番号',
	'label_title': '敬称',
	'label_gender': '性別',
	'label_marital_status': '配偶者の有無',
	'label_email_address': 'Eメールアドレス',
	'label_nationality': '国籍',
	'label_birthday': '誕生日',
	'label_language': '言語選択',
	'label_member_referal': 'メンバー紹介',
	'label_company': 'お勤め先',
	'label_mobile_phone': '携帯電話番号',
	'label_home_phone': '自宅電話番号',
	'label_office_phone': '事業所電話番号',
	'label_fax_number': 'ファックス番号',
	'label_address': '住所',
	'label_state': '都道府県',
	'label_postal_code': '郵便番号',
	'label_city': '居住国',
	'label_join_date': '入会日',
	'label_expiry_date': '有効期限',
	'label_edit_information': '情報を更新する。'
}