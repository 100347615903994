import React, { useEffect, useContext } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { setUserData } from "../../data/setUserData";
import Layout from "../../layouts/ja";
import SEO from "components/seo";
import Member from "../../services/Member";
import Memberlang from "../../data/static-pages/pages/member/ja";
import MemberProfilelang from "../../data/static-pages/pages/member/profile/ja";
import MissingPointLang from "../../data/static-pages/pages/member/missing-point/ja";
import FeedbackLang from "../../data/static-pages/pages/member/feed-back/ja";
import ChangePasswordLang from "../../data/static-pages/pages/member/change-password/ja";
import MyVoucherlang from "../../data/static-pages/pages/member/my-voucher/ja";
import TransactionHistorylang from "../../data/static-pages/pages/member/transaction-history/ja";
import MyReservationlang from "../../data/static-pages/pages/member/my-reservation/ja";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { getGuestCompleteInfo } from "../../apis/guest";

const MemberPage = ({ ...props }) => {
  const userData = setUserData();
  const location = useLocation();
  const loginUrl = "/ja/login";
  const editProfileUrl = "/ja/member/changeprofile";

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const handleCallback = async () => {
      const token = await getAccessTokenSilently();
      const guestInfo = await getGuestCompleteInfo(token);
      if (Object.keys(guestInfo).length > 0) {
        if (!guestInfo?.data?.data?.guest?.isEmailVerify) {
          return navigate(
            `/ja/verify-account?email=${guestInfo?.data?.data?.guest?.email}`,
            {
              replace: true,
            }
          );
        }
        if (
          !guestInfo?.data?.data?.guest?.firstName ||
          !guestInfo?.data?.data?.guest?.lastName
        ) {
          return navigate(`/ja/complete-profile`, {
            replace: true,
          });
        }
      }
    };
    handleCallback();
  }, []);

  return (
    <Layout location={location}>
      <SEO
        title={"Welcome " + userData?.firstname}
        keyword="Welcome"
        decription="Welcome"
      />
      <Member
        props={props}
        editProfileUrl={editProfileUrl}
        location={location}
        loginUrl={loginUrl}
        Memberlang={Memberlang}
        MemberProfilelang={MemberProfilelang}
        MissingPointLang={MissingPointLang}
        FeedbackLang={FeedbackLang}
        ChangePasswordLang={ChangePasswordLang}
        MyVoucherlang={MyVoucherlang}
        TransactionHistorylang={TransactionHistorylang}
        MyReservationlang={MyReservationlang}
      />
    </Layout>
  );
};

export default MemberPage;
