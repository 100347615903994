module.exports = {
  title_info:
    "You can also print out this form and send us via fax: (62-21) 251 1777",
  label_confirmation_no: "確認番号",
  label_guest_name: "お客様名",
  label_list_hotel: "ホテルの名前",
  placeholder_hotel_selected: "ホテルを選択",
  label_check_in_date: "チェックイン日",
  label_check_out_date: "チェックアウト",
  label_file_upload: "アタッチ",
  label_comment: "コメント",
  btn_label: "送る",
  submit_success:
    "不足しているポイントに関するお問い合わせは正常に送信されました。",
  submit_error:
    "お客様のデータを取得する際にエラーが発生しました。再度お試しいただくか、info@ayanarewards.com までお問い合わせください。",
};
