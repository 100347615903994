module.exports = {
  placeholder_email: "Eメールアドレス",
  placeholder_subject: "件名",
  placeholder_hotel: "ホテル名",
  placeholder_selected_hotel: "ホテルを選択",
  placeholder_message: "メッセージ",
  btn_label: "感想を送る",
  submit_success: "フィードバックは正常に送信されました。",
  submit_error:
    "お客様のデータを取得する際にエラーが発生しました。再度お試しいただくか、info@ayanarewards.com までお問い合わせください。",
};
