module.exports = {
    'button_upcoming': '次回',
    'button_history': '履歴',
    'title_confirmation_no': '予約番号',
    'title_booking_date': '予約日時',
    'title_arrival': '到着',
    'title_departure': '出発',
    'title_hotel_name': 'ホテル',
    'title_status': '予約状況',
    'reservation_footnote': '予約情報が更新されるまで、最大2日ほどかかる場合があります。',
}